import {
  GET_SELLER_PROFILE_IMAGES,
  GET_SELLER_PROFILE_IMAGES_FAILURE,
  GET_SELLER_PROFILE_IMAGES_RESET,
  GET_SELLER_PROFILE_IMAGES_SUCCESS,
} from "../_actions/actionType";

const initialState = {
  data: {},
  isFetching: false,
  error: [],
};

export default function GetSellerProfileImages(state = initialState, action) {
  switch (action.type) {
    case GET_SELLER_PROFILE_IMAGES:
      return {
        ...state,
        data: {},
        isFetching: true,
        error: [],
      };
    case GET_SELLER_PROFILE_IMAGES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        error: [],
      };
    case GET_SELLER_PROFILE_IMAGES_FAILURE:
      return {
        ...state,
        isFetching: false,
        data: {},
        error: action.err,
      };
    case GET_SELLER_PROFILE_IMAGES_RESET:
      return {
        ...state,
        isFetching: false,
        data: {},
        error: [],
      };
    default:
      return state;
  }
}
