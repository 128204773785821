import { SET_COMMODITY_ARRAY } from "./types";

const initialState = [];

export const commodityArrayReducer = (
  state = initialState,
  { type, payload }
) => {
  
  switch (type) {
    case SET_COMMODITY_ARRAY:
      
      return { commodityArray: payload };
    default:
      return state;
  }
};
