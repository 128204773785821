import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
const language = ["en", "hindi", "marathi", "assamese", "telugu", "gujarati"];
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng:
      localStorage.getItem("lang") !== null
        ? localStorage.getItem("lang") !== null
        : "en",
    debug: false, // debug: true,
    whitelist: language,
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    nsSeparator: false,
  });

export default i18n;
