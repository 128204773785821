// https://api-uat.arya.ag/api/find-district-and-state-by-pincode/700052

import { RestMethod } from "../../../../_helpers/ApiConfig/RestMethod";
import FilterResponse from "../../../../_helpers/FilterResponse";
import { customToastr } from "../../../../_Pages/_webapp/arya2.0/CustomToastr";

export const callApi = async (
  api,
  Method,
  data,
  filterResp = true,
  errorHandled = false
) => {
  try {
    const response = await RestMethod[Method](api, data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    !errorHandled &&
      customToastr({
        text: "Something went wrong. Please try again later",
        type: "error",
      });
    console.log("error detected while fetching data from api");
    return filterResp ? null : error;
  }
};

export const getFinanceLandingPageDetails = async (urlParamString) => {
  return callApi(
    `/v2/finance/home${urlParamString ? urlParamString : ""}`,
    "GET"
  );
};

export const getBankDetailByIFSC = async (ifscCode) => {
  return callApi(`/v2/bank-detail-by-ifsc/${ifscCode ? ifscCode : ""}`, "GET");
};

export const releaseBagsCalculator = async (payload) => {
  return callApi(`/v2/disbursement/active-loan/release-bags`, "POST", payload);
};

export const applyCreditWallet = async (payload) => {
  return callApi(`/v2/wallet/apply/credit-loan`, "POST", payload, false);
};

export const getLoanStatementDetails = async (payload) => {
  return callApi("/v2/disbursement/active-loan/statement", "POST", payload);
};
