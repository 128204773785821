const INITIAL_STATE = {
  pageNumber: 1,
  createAuctionData: {
    commodity: "",
    variety: "",
    Arya: "",
    seasonSelected: "",
    warehouseSelected: "",
    auctionQuantity: "",
    auctionType: "English",
    resPrice: "",
    incPrice: "",
    minQuantity: "",
    imageUrl: "",
    videoUrl: "",

    dateValue: "",
    startHour: "",
    startMin: "",
    endHour: "",
    endMin: "",

    sellerName: "",
    varietyPrefill: false,
  },
};

export default function marketplaceAuctionDataReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case "CHANGE_MARKETPLACE_AUCTION_DATA":
      console.log("CHANGE_MARKETPLACE_AUCTION_DATA", state, action.data);
      return {
        ...state,
        pageNumber: action?.data?.pageNumber ? action.data.pageNumber : 1,
        createAuctionData: {
          commodity: action.data.commodity
            ? action.data.commodity
            : state.createAuctionData.commodity,
          variety: action.data.variety
            ? action.data.variety
            : state.createAuctionData.variety,
          Arya: action.data.Arya
            ? action.data.Arya
            : state.createAuctionData.Arya,
          seasonSelected: action.data.seasonSelected
            ? action.data.seasonSelected
            : state.createAuctionData.seasonSelected,
          warehouseSelected: action.data.warehouseSelected
            ? action.data.warehouseSelected
            : state.createAuctionData.warehouseSelected,
          videoUrl: action.data.videoUrl
            ? action.data.videoUrl
            : state.createAuctionData.videoUrl,
          auctionQuantity: action.data.auctionQuantity
            ? action.data.auctionQuantity
            : state.createAuctionData.auctionQuantity,
          minQuantity: action.data.minQuantity
            ? action.data.minQuantity
            : state.createAuctionData.minQuantity,
          auctionType: action.data.auctionType
            ? action.data.auctionType
            : state.createAuctionData.auctionType,
          resPrice: action.data.resPrice
            ? action.data.resPrice
            : state.createAuctionData.resPrice,
          incPrice: action.data.incPrice
            ? action.data.incPrice
            : state.createAuctionData.incPrice,
          imageUrl: action.data.imageUrl
            ? action.data.imageUrl
            : state.createAuctionData.imageUrl,
          dateValue: action.data.dateValue
            ? action.data.dateValue
            : state.createAuctionData.dateValue,
          startHour: action.data.startHour
            ? action.data.startHour
            : state.createAuctionData.startHour,
          startMin: action.data.startMin
            ? action.data.startMin
            : state.createAuctionData.startMin,
          endHour: action.data.endHour
            ? action.data.endHour
            : state.createAuctionData.endHour,
          endMin: action.data.endMin
            ? action.data.endMin
            : state.createAuctionData.endMin,
          sellerName: action.data.sellerName
            ? action.data.sellerName
            : state.createAuctionData.sellerName,
          varietyPrefill: action.data.varietyPrefill
            ? action.data.varietyPrefill
            : state.createAuctionData.varietyPrefill,
        },
      };
    case "RESET_MARKETPLACE_AUCTION_DATA":
      return {
        ...state,
        pageNumber: 1,
        createAuctionData: {
          commodity: "",
          variety: "",
          Arya: "",
          seasonSelected: "",
          warehouseSelected: "",
          auctionQuantity: "",
          minQuantity: "",
          auctionType: "English",
          resPrice: "",
          incPrice: "",
          imageUrl: "",
          videoUrl: "",
          dateValue: "",
          startHour: "",
          startMin: "",
          endHour: "",
          endMin: "",

          sellerName: "",
          varietyPrefill: false,
        },
      };
    default:
      return state;
  }
}
