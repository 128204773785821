import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
// import { stopReportingRuntimeErrors } from "react-error-overlay";
import { URL_CONFIG } from "./_constants/Config/URL_CONFIG";
import HeaderProducer from "./_components/ContextApis/HeaderContext";
import DefaultLoader from "./_Pages/_webapp/arya2.0/CommonComponents/Loader/DefaultLoader";

// if (URL_CONFIG.ENVIROEMENT === "DEV") {
//   stopReportingRuntimeErrors();
// }

// const isMobile = window.matchMedia(
//   "only screen and (max-width: 1023px)"
// ).matches;

ReactDOM.render(
  <Suspense
    fallback={
      // window.location.pathname=== '/'? <div className="position-relative">
      //   <div className="image-div">
      //   <img  src={require(isMobile?'./assets/images/HomeImage/home-screen-mobile.jpg':'./assets/images/HomeImage/home-screen-desktop.jpg')} alt=""></img>
      //   </div>
      // </div>:<Loader isLoading={true} />
      <DefaultLoader isLoading={true} />
    }
  >
    <HeaderProducer>
      <App />
    </HeaderProducer>
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
