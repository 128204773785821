import React, { useRef } from "react";
// import MultiSelect from "../CommonComponents/MultiSelect/MultiSelect";

export default function TestSelect() {
  const ref = useRef(false);

  // console.log({ flag });
  return (
    <div className="App">
      {/* <MultiSelect
        options={options}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable={false}
        getValue={getValueHandeler}
        value={initialValue}
      ></MultiSelect> */}

      <div ref={ref} className="bg-danger w-25">
        hello
      </div>
    </div>
  );
}
