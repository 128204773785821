import {
  GET_MY_REQUIREMENTS,
  GET_MY_REQUIREMENTS_FAILURE,
  GET_MY_REQUIREMENTS_SUCCESS,
  GET_MY_REQUIREMENTS_RESET,
} from "../_actions/actionType";

  const initialState = {
    data: {},
    isFetching: false,
    error: [],
  };
  
  export default function GetMyRequirements(state = initialState, action) {
    switch (action.type) {
      case GET_MY_REQUIREMENTS:
        return {
          ...state,
          data: {},
          isFetching: true,
          error: [],
        };
      case GET_MY_REQUIREMENTS_SUCCESS:
        return {
          ...state,
          isFetching: false,
          data: action.data,
          error: [],
        };
      case GET_MY_REQUIREMENTS_FAILURE:
        return {
          ...state,
          isFetching: false,
          data: {},
          error: action.err,
        };
      case GET_MY_REQUIREMENTS_RESET:
        return {
          ...state,
          isFetching: false,
          data: {},
          error: [],
        };
      default:
        return state;
    }
  }
  