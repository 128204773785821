import { recentConstants } from '../_constants/Redux';

const INITIAL_STATE = {
    recent_warehouses: [1,2,3,4,5,6,7,8],   
  }

export default function recent(state = INITIAL_STATE, action) {
  switch (action.type) {
    case recentConstants.ADD:
    return {
      ...state,
      recent_warehouses: [action.id].concat(state.recent_warehouses),
    };
    case recentConstants.SUCCESS:
      return {
        ...state,
        type: 'recent-success',
        message: action.message
      };
    case recentConstants.ERROR:
      return {
        ...state,
        type: 'recent-danger',
        message: action.message
      };
    case recentConstants.CLEAR:
      return {};
    default:
      return state
  }
}