import React from "react";
import competitor from "./Competitor_Mapping1.png";
import first from "./Business_Modal1.png";
import second from "./Service_Modal1.png";
import third from "./Market_Presence1.png";
import fourth from "./Target_Segment1.png";
import { useState } from "react";
import { useEffect } from "react";
import "./style.css";

const BuisnessNeed = () => {
  const [imageNumber, setImageNumber] = useState("");
  useEffect(() => {
    let a = document.body?.classList.add("buisnessNeedPadding");
    return () => {
      let a = document.body?.classList.remove("buisnessNeedPadding");
    };
  }, []);
  let imageSelected =
    imageNumber == "1"
      ? first
      : imageNumber == "2"
      ? second
      : imageNumber == "3"
      ? third
      : imageNumber == "4"
      ? fourth
      : competitor;
  return (
    <>
      <div>
        <img src={imageSelected} usemap="#Map" width="1210" height="569" />
        <map name="Map">
          <area
            shape="rect"
            coords="58,138,267,188"
            onClick={() => {
              setImageNumber("1");
            }}
          />
          <area
            shape="rect"
            coords="339,138,571,187"
            onClick={() => {
              setImageNumber("2");
            }}
          />
          <area
            shape="rect"
            coords="632,135,827,184"
            onClick={() => {
              setImageNumber("3");
            }}
          />
          <area
            shape="rect"
            coords="921,135,1124,181"
            onClick={() => {
              setImageNumber("4");
            }}
          />
        </map>
      </div>
    </>
  );
};
export default BuisnessNeed;
