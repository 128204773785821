import { cacheData } from "../_constants/Redux/CacheData";
import { OPTION_CONSTANT } from "../_constants/Redux/OPTION_CONSTANT";

const INITIAL_STATE = {
  option_master: [],
  filters: "",
  options_master: "",
  city_id: "",
  state_id: "",
  backupCityArr: "",
  states: {isFetching:true,data:""},
};

export default function option(state = INITIAL_STATE, action) {
  switch (action.type) {
    case cacheData.FETCHING_GET_STATE_DATA:
      return {
        ...state,
        states: { ...state.states, ...action.data },
      };
    case cacheData.GET_STATE_DATA:
      return {
        ...state,
        states: action.data ,
      };
    case OPTION_CONSTANT.ADD:
      return {
        ...state,
        option_master: action.data,
      };
    case OPTION_CONSTANT.FILTER:
      return {
        ...state,
        filters: action.data,
      };
    case OPTION_CONSTANT.OPTIONS:
      return {
        ...state,
        options_master: action.data,
      };
    case OPTION_CONSTANT.CLEAR:
      return {};
    case "COMMODITY":
      return {
        ...state,
        commodity: action.data.commodity,
        stateName: action.data.stateName,
        cityName: action.data.cityName,
        cityArray: action.data.cityArray,
        statePlaceName: action.data?.statePlaceName
          ? action.data.statePlaceName
          : "",
        nameOfCity: action.data?.nameOfCity ? action.data.nameOfCity : "",
        city_id: action.data.cityName ? action.data.cityName : state.city_id,
        state_id: action.data.stateName
          ? action.data.stateName
          : state.state_id,
        backupCityArr: action.data?.cityArray
          ? action.data.cityArray
          : state.backupCityArr,
      };
    default:
      return state;
  }
}
