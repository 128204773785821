import {
    GET_ENAM_STORAGE,
    GET_ENAM_STORAGE_FAILURE,
    GET_ENAM_STORAGE_SUCCESS,
    GET_ENAM_STORAGE_RESET,
  } from "../_actions/actionType";
  
    const initialState = {
      data: {},
      isFetching: false,
      error: [],
    };
    
    export default function GetEnamStorage(state = initialState, action) {
      switch (action.type) {
        case GET_ENAM_STORAGE:
          return {
            ...state,
            data: {},
            isFetching: true,
            error: [],
          };
        case GET_ENAM_STORAGE_SUCCESS:
          return {
            ...state,
            isFetching: false,
            data: action.data,
            error: [],
          };
        case GET_ENAM_STORAGE_FAILURE:
          return {
            ...state,
            isFetching: false,
            data: {},
            error: action.err,
          };
        case GET_ENAM_STORAGE_RESET:
          return {
            ...state,
            isFetching: false,
            data: {},
            error: [],
          };
        default:
          return state;
      }
    }
    