import {
  MAKE_CAMPAIGN_COMPLETE,
  SET_CAMPAIGN_PAGE,
  SET_CAMPAIGN_STATE,
  SET_CAMPAIGN_TYPE,
  SET_SELECT_COMODITY_STATE,
  UPDATE_COMMODITY_OPTIONS,
  UPDATE_VARIETY_OPTIONS,
  UPDATE_WAREHOUSE_STATE,
  SET_WAREHOUSE_STATES,
  SET_FILTER_DATA,
  CLEAR_FILTER_DATA,
} from "../_actions/actionType";
import moment from "moment";
import { CAMPAIGN_TYPE } from "../_Pages/_webapp/priceCapture/Constant";
const initialState = {
  selectComododityState: {
    fromDate: moment().startOf("month").toDate().toString(),
    toDate: moment().toDate().toString(),
    warehouseStates: [],
    warehouseState: { id: "", name: "" },
    commodity: { id: "", name: "" },
    variety: { id: "", name: "" },
    commodityOptions: [],
    varietyOptions: [],
  },
  campaignState: {
    type: CAMPAIGN_TYPE.NONE,
    page: 0,
    availableQuantity: "",
    sellingPrice: "",
    markup: {
      value: { markupPrice: "", selected: false },
      percentage: { markupPercent: "", selected: false },
    },
    completed: false,
  },
  filterState: {
    filteredData: {},
    filterUIData: [],
    active: false,
  },
};

const priceCaptureState = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECT_COMODITY_STATE:
      return { ...state, selectComododityState: action.payload };
    case UPDATE_WAREHOUSE_STATE:
      return {
        ...state,
        selectComododityState: {
          ...state.selectComododityState,
          warehouseState: action.payload,
        },
      };
    case SET_WAREHOUSE_STATES:
      return {
        ...state,
        selectComododityState: {
          ...state.selectComododityState,
          warehouseStates: action.payload,
        },
      };
    case UPDATE_COMMODITY_OPTIONS:
      return {
        ...state,
        selectComododityState: {
          ...state.selectComododityState,
          commodityOptions: action.payload,
        },
      };
    case UPDATE_VARIETY_OPTIONS:
      return {
        ...state,
        selectComododityState: {
          ...state.selectComododityState,
          varietyOptions: action.payload,
        },
      };
    case SET_CAMPAIGN_STATE:
      return { ...state, campaignState: action.payload };
    case SET_CAMPAIGN_TYPE:
      return {
        ...state,
        campaignState: {
          ...state.campaignState,
          type: action.payload,
          page:
            action.payload == CAMPAIGN_TYPE.MOTHER
              ? 2
              : action.payload == CAMPAIGN_TYPE.SELLER
              ? 1
              : 0,
        },
      };
    case SET_CAMPAIGN_PAGE:
      return {
        ...state,
        campaignState: {
          ...state.campaignState,
          page: action.payload,
        },
      };
    case MAKE_CAMPAIGN_COMPLETE:
      return {
        ...state,
        campaignState: {
          ...state.campaignState,
          completed: true,
        },
      };
    case SET_FILTER_DATA:
      return {
        ...state,
        filterState: {
          filteredData: action.payload.filteredData,
          filterUIData: action.payload.filterUIData,
          active: true,
        },
      };
    case CLEAR_FILTER_DATA:
      return {
        ...state,
        filterState: {
          filteredData: {},
          filterUIData: [],
          active: false,
        },
      };

    default:
      return state;
  }
};

export default priceCaptureState;
