import React from "react";
const DefaultLoader = ({
  defaultLoader = "new-loader_positionAbsolute",
  showLoaderAtCenter = "new-loader_loader-container",
}) => {
  return (
    <div class={`new-loader ${defaultLoader}`}>
      <div class={showLoaderAtCenter}>
        <svg class="new-loader_spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            stroke="#85C5B6"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </div>
    </div>
  );
};

export default DefaultLoader;
