import {
  GET_CITY_BY_STATE,
  GET_CITY_BY_STATE_FAILURE,
  GET_CITY_BY_STATE_SUCCESS,
  GET_CITY_BY_STATE_RESET,
} from "../_actions/actionType";

const initialState = {
  data: {},
  isFetching: false,
  error: [],
};

export default function GetCityByState(state = initialState, action) {
  switch (action.type) {
    case GET_CITY_BY_STATE:
      return {
        ...state,
        data: {},
        isFetching: true,
        error: [],
      };
    case GET_CITY_BY_STATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        error: [],
      };
    case GET_CITY_BY_STATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        data: {},
        error: action.err,
      };
    case GET_CITY_BY_STATE_RESET:
      return {
        ...state,
        isFetching: false,
        data: {},
        error: [],
      };
    default:
      return state;
  }
}
