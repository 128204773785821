import { ARYA_SHAKTI_NUMBER } from "./types";

const initialState = { arya_shakti_number: "" };

export const aryaShaktiMobileReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case ARYA_SHAKTI_NUMBER:
      return { ...state, arya_shakti_number: payload.number };
    default:
      return state;
  }
};
