import { USER_DATA_CONSTANT } from '../_constants/Redux/userData.constant';

const INITIAL_STATE = {
    compareWarehouses: [],
    compareParameters: [],
    cartCount: 0,
    userDataCache:""
};

export default function userData(state = INITIAL_STATE, action) {
    switch (action.type) {
        case USER_DATA_CONSTANT.ADD_COMPARE:
            return {
                ...state,
                compareWarehouses: [...state.compareWarehouses, action.data]
            };
        case USER_DATA_CONSTANT.REMOVE_COMPARE:
            return {
                ...state,
                compareWarehouses: state.compareWarehouses.filter((compareWarehouse) => compareWarehouse.id !== action.data.id)
            };
        case USER_DATA_CONSTANT.CLEAR_COMPARE:
            return {
                ...state,
                compareWarehouses: []
            };
        case USER_DATA_CONSTANT.ADD_COMPARE_PARAMETER:
            return {
                ...state,
                compareParameters: action.data
            };
        case USER_DATA_CONSTANT.ADD_CART_COUNT:
            return {
                ...state,
                cartCount: state.cartCount + action.data
            };
        case USER_DATA_CONSTANT.MINUS_CART_COUNT:
            return {
                ...state,
                cartCount: state.cartCount - action.data
            };
        case USER_DATA_CONSTANT.CLEAR_CART_COUNT:
            return {
                ...state,
                cartCount: 0
            };
        case USER_DATA_CONSTANT.ADD_DATA_CACHE:
            return {
                ...state,
                userDataCache: action.data
            };
        default:
            return state;
    }
}