import { RestMethod } from "../../_helpers/ApiConfig/RestMethod";
import FilterResponse, {
  FilterResponseV2,
} from "../../_helpers/FilterResponse";

const callApi = async (api, Method, data, type) => {
  try {
    const response = await RestMethod[Method](api, data);
    if (type === "single") {
      const api_response = FilterResponse(response); //If respone 200 to 299 Then return
      return api_response;
    } else {
      const api_response = FilterResponseV2(response); //If respone 200 to 299 Then return
      return api_response.data[0] ? api_response.data[0] : api_response.data;
    }
  } catch (error) {
    // toastr.error('Something Went Wrong')
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getDepositorViewTokensData = (queryParam, offset, limit) => {
  var reuestlink = `/blockchain/depositor/verify-tokens?${
    limit ? "offset=" + offset : ""
  }${limit ? "&limit=" + limit : ""}${queryParam ? queryParam : ""}`;
  return callApi(reuestlink, "GET");
};

export const getDepositorApplyLoanData = (offset, limit, queryParam) => {
  var reuestlink = `/blockchain/depositor/apply-loan?${
    limit ? "offset=" + offset : ""
  }${limit ? "&limit=" + limit : ""}${queryParam ? queryParam : ""}`;
  return callApi(reuestlink, "GET");
};

export const getDepositorLoanStatusData = (offset, limit, queryParam) => {
  var reuestlink = `/blockchain/depositor/loan-status?${
    limit ? "offset=" + offset : ""
  }${limit ? "&limit=" + limit : ""}${queryParam ? queryParam : ""}`;
  return callApi(reuestlink, "GET");
};

export const depositorApplyLoan = (data) => {
  var reuestlink = `/blockchain/depositor/apply-loan`;
  return callApi(reuestlink, "POST", data);
};

export const updateLoanStatus = (data) => {
  var reuestlink = `/blockchain/depositor/status-update`;
  return callApi(reuestlink, "POST", data);
};

export const loanStatusBankOffers = (id, loan_req_id,loan_offer_id) => {
  var reuestlink = `/blockchain/depositor/loan-details/${id}${
    loan_req_id ? `?loan_request_id=${loan_req_id}&loan_offer_id=${loan_offer_id}` : `?loan_offer_id=${loan_offer_id}`
  }`;
  return callApi(reuestlink, "GET");
};

export const acceptReactTokens = async (data) => {
  var reuestlink = `/blockchain/depositor/accept-reject-tokens`;
  const response = await RestMethod.POST(reuestlink, data);
  return response.data;
};

export const viewTokenDetails = (id) => {
  var reuestlink = `/blockchain/v1/token/${id}`;
  return callApi(reuestlink, "GET");
};

export const getStackInfo = (Arr, clients) => {
  var reuestlink = `/blockchain/v1/stack-info?filters=${Arr}`;
  return callApi(reuestlink, "GET");
};

export const getHashByID = (id) => {
  var reuestlink = `/blockchain/depositor/user-hash/${id}`;
  return callApi(reuestlink, "GET");
};
