/**
 * action type for reducers
 */
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_FAILURE = "GET_ALL_PRODUCTS_FAILURE";
export const GET_ALL_PRODUCTS_RESET = "GET_ALL_PRODUCTS_RESET";

export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS";
export const GET_PRODUCT_DETAILS_FAILURE = "GET_PRODUCT_DETAILS_FAILURE";
export const GET_PRODUCT_DETAILS_RESET = "GET_PRODUCT_DETAILS_RESET";

export const GET_SELLER_DETAILS = "GET_SELLER_DETAILS";
export const GET_SELLER_DETAILS_SUCCESS = "GET_SELLER_DETAILS_SUCCESS";
export const GET_SELLER_DETAILS_FAILURE = "GET_SELLER_DETAILS_FAILURE";
export const GET_SELLER_DETAILS_RESET = "GET_SELLER_DETAILS_RESET";

export const GET_SELLER_PRODUCTS = "GET_SELLER_PRODUCTS";
export const GET_SELLER_PRODUCTS_SUCCESS = "GET_SELLER_PRODUCTS_SUCCESS";
export const GET_SELLER_PRODUCTS_FAILURE = "GET_SELLER_PRODUCTS_FAILURE";
export const GET_SELLER_PRODUCTS_RESET = "GET_SELLER_PRODUCTS_RESET";

export const GET_SIMILAR_PRODUCTS = "GET_SIMILAR_PRODUCTS";
export const GET_SIMILAR_PRODUCTS_SUCCESS = "GET_SIMILAR_PRODUCTS_SUCCESS";
export const GET_SIMILAR_PRODUCTS_FAILURE = "GET_SIMILAR_PRODUCTS_FAILURE";
export const GET_SIMILAR_PRODUCTS_RESET = "GET_SIMILAR_PRODUCTS_RESET";

export const POST_ENQUIRY = "POST_ENQUIRY";
export const POST_ENQUIRY_SUCCESS = "POST_ENQUIRY_SUCCESS";
export const POST_ENQUIRY_FAILURE = "POST_ENQUIRY_FAILURE";
export const POST_ENQUIRY_RESET = "POST_ENQUIRY_RESET";

export const VERIFY_PAN = "VERIFY_PAN";
export const VERIFY_PAN_SUCCESSFUL = "VERIFY_PAN_SUCCESSFUL";
export const VERIFY_PAN_FAILED = "VERIFY_PAN_FAILED";
export const VERIFY_PAN_RESET = "VERIFY_PAN_RESET";

// marketplace create auction actions
export const CHANGE_MARKETPLACE_AUCTION_DATA =
  "CHANGE_MARKETPLACE_AUCTION_DATA";
export const RESET_MARKETPLACE_AUCTION_DATA = "RESET_MARKETPLACE_AUCTION_DATA";

export const GET_SELLER_PROFILE_IMAGES = "GET_SELLER_PROFILE_IMAGES";
export const GET_SELLER_PROFILE_IMAGES_SUCCESS =
  "GET_SELLER_PROFILE_IMAGES_SUCCESS";
export const GET_SELLER_PROFILE_IMAGES_FAILURE =
  "GET_SELLER_PROFILE_IMAGES_FAILURE";
export const GET_SELLER_PROFILE_IMAGES_RESET =
  "GET_SELLER_PROFILE_IMAGES_RESET";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";
export const ADD_PRODUCT_RESET = "ADD_PRODUCT_RESET";

export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";
export const UPDATE_PRODUCT_RESET = "UPDATE_PRODUCT_RESET";

export const GET_COMMODITY_DROPDOWN = "GET_COMMODITY_DROPDOWN";
export const GET_COMMODITY_DROPDOWN_SUCCESS = "GET_COMMODITY_DROPDOWN_SUCCESS";
export const GET_COMMODITY_DROPDOWN_FAILURE = "GET_COMMODITY_DROPDOWN_FAILURE";
export const GET_COMMODITY_DROPDOWN_RESET = "GET_COMMODITY_DROPDOWN_RESET";

export const POST_SELL_REQUIREMENT = "POST_SELL_REQUIREMENT";
export const POST_SELL_REQUIREMENT_SUCCESS = "POST_SELL_REQUIREMENT_SUCCESS";
export const POST_SELL_REQUIREMENT_FAILURE = "POST_SELL_REQUIREMENT_FAILURE";
export const POST_SELL_REQUIREMENT_RESET = "POST_SELL_REQUIREMENT_RESET";

export const CONTACT_SELLER = "CONTACT_SELLER";
export const CONTACT_SELLER_SUCCESS = "CONTACT_SELLER_SUCCESS";
export const CONTACT_SELLER_FAILURE = "CONTACT_SELLER_FAILURE";
export const CONTACT_SELLER_RESET = "CONTACT_SELLER_RESET";

export const GET_MY_REQUIREMENTS = "GET_MY_REQUIREMENTS";
export const GET_MY_REQUIREMENTS_SUCCESS = "GET_MY_REQUIREMENTS_SUCCESS";
export const GET_MY_REQUIREMENTS_FAILURE = "GET_MY_REQUIREMENTS_FAILURE";
export const GET_MY_REQUIREMENTS_RESET = "GET_MY_REQUIREMENTS_RESET";

export const MAKE_REQUIREMENT_PUBLIC = "MAKE_REQUIREMENT_PUBLIC";
export const MAKE_REQUIREMENT_PUBLIC_SUCCESS =
  "MAKE_REQUIREMENT_PUBLIC_SUCCESS";
export const MAKE_REQUIREMENT_PUBLIC_FAILURE =
  "MAKE_REQUIREMENT_PUBLIC_FAILURE";
export const MAKE_REQUIREMENT_PUBLIC_RESET = "MAKE_REQUIREMENT_PUBLIC_RESET";

export const GET_MY_REQUIREMENTS_FILTER = "GET_MY_REQUIREMENTS_FILTER";
export const GET_MY_REQUIREMENTS_FILTER_SUCCESS =
  "GET_MY_REQUIREMENTS_FILTER_SUCCESS";
export const GET_MY_REQUIREMENTS_FILTER_FAILURE =
  "GET_MY_REQUIREMENTS_FILTER_FAILURE";
export const GET_MY_REQUIREMENTS_FILTER_RESET =
  "GET_MY_REQUIREMENTS_FILTER_RESET";

export const GET_CITY_BY_STATE = "GET_CITY_BY_STATE";
export const GET_CITY_BY_STATE_SUCCESS = "GET_CITY_BY_STATE_SUCCESS";
export const GET_CITY_BY_STATE_FAILURE = "GET_CITY_BY_STATE_FAILURE";
export const GET_CITY_BY_STATE_RESET = "GET_CITY_BY_STATE_RESET";

export const GET_ENAM_STORAGE = "GET_ENAM_STORAGE";
export const GET_ENAM_STORAGE_SUCCESS = "GET_ENAM_STORAGE_SUCCESS";
export const GET_ENAM_STORAGE_FAILURE = "GET_ENAM_STORAGE_FAILURE";
export const GET_ENAM_STORAGE_RESET = "GET_ENAM_STORAGE_RESET";

export const GET_BUYER_QUOTE = "GET_BUYER_QUOTE";
export const GET_BUYER_QUOTE_SUCCESS = "GET_BUYER_QUOTE_SUCCESS";
export const GET_BUYER_QUOTE_FAILURE = "GET_BUYER_QUOTE_FAILURE";
export const GET_BUYER_QUOTE_RESET = "GET_BUYER_QUOTE_RESET";

export const GET_BUYER_QUOTE_SELLER = "GET_BUYER_QUOTE_SELLER";
export const GET_BUYER_QUOTE_SELLER_SUCCESS = "GET_BUYER_QUOTE_SELLER_SUCCESS";
export const GET_BUYER_QUOTE_SELLER_FAILURE = "GET_BUYER_QUOTE_SELLER_FAILURE";
export const GET_BUYER_QUOTE_SELLER_RESET = "GET_BUYER_QUOTE_SELLER_RESET";

//Blockchain action starts here
// Depositor

export const RESET_APPLY_LOAN_FORM_STATE = "RESET_APPLY_LOAN_FORM_STATE";
export const SET_APPLY_LOAN_FORM_STATE = "SET_APPLY_LOAN_FORM_STATE";
export const UPDATE_PAGE_NUMBER = "UPDATE_PAGE_NUMBER";
export const UPDATE_SELECTED_TOKENS = "UPDATE_SELECTED_TOKENS";
export const UPDATE_TOKEN_LIST = "UPDATE_TOKEN_LIST";
export const UPDATE_UNVERIFIED_TOKENS = "UPDATE_UNVERIFIED_TOKENS";
export const UPDATE_QUANTITY = "UPDATE_QUANTITY";
export const UPDATE_MAX_QUANTITY = "UPDATE_MAX_QUANTITY";

//Banker
//Blockchain action ends here
// price capture
export const SET_SELECT_COMODITY_STATE = "SET_SELECT_COMODITY_STATE";
export const UPDATE_WAREHOUSE_STATE = "UPDATE_WAREHOUSE_STATE";
export const SET_WAREHOUSE_STATES = "SET_WAREHOUSE_STATES";
export const UPDATE_COMMODITY_OPTIONS = "UPDATE_COMMODITY_OPTIONS";
export const UPDATE_VARIETY_OPTIONS = "UPDATE_VARIETY_OPTIONS";
export const RESET_SELECT_COMODITY_STATE = "RESET_SELECT_COMODITY_STATE";

export const SET_CAMPAIGN_STATE = "SET_CAMPAIGN_STATE";
export const SET_CAMPAIGN_TYPE = "SET_CAMPAIGN_TYPE";
export const SET_CAMPAIGN_PAGE = "SET_CAMPAIGN_PAGE";
export const MAKE_CAMPAIGN_COMPLETE = "MAKE_CAMPAIGN_COMPLETE";

export const SET_FILTER_DATA = "SET_FILTER_DATA";
export const CLEAR_FILTER_DATA = "CLEAR_FILTER_DATA";
