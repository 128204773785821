import {
  SET_APPLY_LOAN_FORM_STATE,
  UPDATE_MAX_QUANTITY,
  UPDATE_PAGE_NUMBER,
  UPDATE_QUANTITY,
  UPDATE_SELECTED_TOKENS,
  UPDATE_TOKEN_LIST,
  UPDATE_UNVERIFIED_TOKENS,
} from "../_actions/actionType";

const initialState = {
  applyFormState: {
    //  first
    pending: false,
    page: 0,
    fromDetailPage: false,
    detailPageUrl: "",
    id: 0,
    // loanRequestSentModalState: false,
    page1Data: {
      scrollableTokenList: false,
      maxQuantity: 0,
      quantity: "",
      tokenList: [],
      selectedBanks: [],
      selectedTokens: [],
    },
    page2Data: {
      unverifiedTokenList: [],
    },
    bankData: [],
  },
};

const blkcnDpReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APPLY_LOAN_FORM_STATE:
      return { ...state, applyFormState: action.payload };
    case UPDATE_PAGE_NUMBER:
      return {
        ...state,
        applyFormState: { ...state.applyFormState, page: action.payload },
      };
    case UPDATE_SELECTED_TOKENS:
      return {
        ...state,
        applyFormState: {
          ...state.applyFormState,
          page1Data: {
            ...state.applyFormState.page1Data,
            selectedTokens: action.payload,
          },
        },
      };
    case UPDATE_QUANTITY:
      return {
        ...state,
        applyFormState: {
          ...state.applyFormState,
          page1Data: {
            ...state.applyFormState.page1Data,
            quantity: action.payload,
          },
        },
      };
    case UPDATE_MAX_QUANTITY:
      return {
        ...state,
        applyFormState: {
          ...state.applyFormState,
          page1Data: {
            ...state.applyFormState.page1Data,
            maxQuantity: action.payload,
          },
        },
      };
    case UPDATE_TOKEN_LIST:
      return {
        ...state,
        applyFormState: {
          ...state.applyFormState,
          page1Data: {
            ...state.applyFormState.page1Data,
            tokenList: action.payload,
          },
        },
      };
    case UPDATE_UNVERIFIED_TOKENS:
      return {
        ...state,
        applyFormState: {
          ...state.applyFormState,
          page2Data: {
            unverifiedTokenList: action.payload,
          },
        },
      };
    default:
      return state;
  }
};

export default blkcnDpReducer;
