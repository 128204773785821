import { getHashByID } from "../_services/Blockchain/depositer.service";

export const commonActions = {
  arrayTofiltersting,
};

function arrayTofiltersting(array) {
  let temp = "";
  if (array && array.length !== 0) {
    array.map((data, index) => {
      temp = temp === "" ? data : temp + "," + data;
    });
    return temp;
  } else {
    return "";
  }
}

export const isHashCode = async () => {
  let hash = localStorage.getItem("user_hash");
  if (!hash) {
    console.log("ssssss1");
    let { arya_id = "" } = JSON.parse(
      localStorage.getItem("userDashBoardData")
    )?.member;
    if (!arya_id) return false;
    await getHashByID(arya_id).then((res) => {
      if (res.user_hash) {
        console.log("ssssss3");
        localStorage.setItem("user_hash", res.user_hash);
        return true;
      } else {
        console.log("ssssss4");
        return false;
      }
    });
  } else {
    console.log("ssssss2");
    return true;
  }
};
