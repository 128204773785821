import {
  GET_ALL_PRODUCTS,
  GET_ALL_PRODUCTS_FAILURE,
  GET_ALL_PRODUCTS_RESET,
  GET_ALL_PRODUCTS_SUCCESS,
} from "../_actions/actionType";

const initialState = {
  data: {},
  isFetching: false,
  error: [],
};

export default function GetAllProducts(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        data: {},
        isFetching: true,
        error: [],
      };
    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        error: [],
      };
    case GET_ALL_PRODUCTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        data: {},
        error: action.err,
      };
    case GET_ALL_PRODUCTS_RESET:
      return {
        ...state,
        isFetching: false,
        data: {},
        error: [],
      };
    default:
      return state;
  }
}
