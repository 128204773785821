import { BLOCKCHAIN } from "../_constants/Redux";

const initialState = {
  data: {},
};

export default function blockchain(state = initialState, action) {
  switch (action.type) {
    case BLOCKCHAIN.HISTORY:
      return {
        ...state,
        data: action.data,
      };

    default:
      return state;
  }
}
