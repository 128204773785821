import {
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_RESET,
  UPDATE_PRODUCT_SUCCESS,
} from "../_actions/actionType";

const initialState = {
  data: {},
  isFetching: false,
  error: [],
};

export default function UpdateProduct(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PRODUCT:
      return {
        ...state,
        data: {},
        isFetching: true,
        error: [],
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        error: [],
      };
    case UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        isFetching: false,
        data: {},
        error: action.err,
      };
    case UPDATE_PRODUCT_RESET:
      return {
        ...state,
        isFetching: false,
        data: {},
        error: [],
      };
    default:
      return state;
  }
}
