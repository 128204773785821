
const INITIAL_STATE = {
    pageNumber:1,
    createAuctionData:{ commodity:'',
        variety : '',
        Arya:'',
        seasonSelected:'',
        warehouseSelected:'',
        auctionQuantity:'',
        auctionType : 'English',
        resPrice:'',
        incPrice:'',
        minQuantity:'',
        imageUrl:'',
        videoUrl:''
    
    }

};

export default function auctionData(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'PAGENUMBER':
            console.log(action)
            return {
                ...state,
                pageNumber: action.data.pageNumber,
                createAuctionData:{ 
                    commodity:action.data.commodity?action.data.commodity:state.createAuctionData.commodity,
                    variety : action.data.variety?action.data.variety:state.createAuctionData.variety,
                    Arya:action.data.Arya?action.data.Arya:state.createAuctionData.Arya,
                    seasonSelected:action.data.seasonSelected?action.data.seasonSelected:state.createAuctionData.seasonSelected,
                    warehouseSelected:action.data.warehouseSelected?action.data.warehouseSelected:state.createAuctionData.warehouseSelected,
                    videoUrl:action.data.videoUrl?action.data.videoUrl:state.createAuctionData.videoUrl,
                    auctionQuantity:action.data.auctionQuantity?action.data.auctionQuantity:state.createAuctionData.auctionQuantity,
                    minQuantity:action.data.minQuantity?action.data.minQuantity:state.createAuctionData.minQuantity,
                    auctionType : action.data.auctionType?action.data.auctionType:state.createAuctionData.auctionType,
                    resPrice:action.data.resPrice?action.data.resPrice:state.createAuctionData.resPrice,
                    incPrice:action.data.incPrice?action.data.incPrice:state.createAuctionData.incPrice,
                    imageUrl:action.data.imageUrl?action.data.imageUrl:state.createAuctionData.imageUrl,
                }
            };
            case 'RESET':
            console.log(action)
            return {
                ...state,
                pageNumber: 1,
                createAuctionData:{ 
                    commodity:"",
                    variety : "",
                    Arya:"",
                    seasonSelected:"",
                    warehouseSelected:"",
                    auctionQuantity:"",
                    minQuantity:"",
                    auctionType : "English",
                    resPrice:"",
                    incPrice:"",
                    imageUrl:"",
                    videoUrl:''
                }
            };
        default:
            return state;
    }
}