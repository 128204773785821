export const filterArrangement = [
  { type: 1, name: "Variety", key: "varieties", filterKey: "variety_name" },
  {
    type: 2,
    name: "Arival City",
    key: "warehouse_city",
    filterKey: "warehouse_city_ids",
  },
  {
    type: 3,
    name: "Arrival Warehouse",
    key: "warehouse",
    filterKey: "warehouse_ids",
  },
  { type: 4, name: "Seller Name", key: "suppliers", filterKey: "supplier_ids" },
  {
    type: 5,
    name: "Source State",
    key: "supplier_state",
    filterKey: "supplier_state_name",
  },
  {
    type: 6,
    name: "Source Location",
    key: "supplier_city",
    filterKey: "supplier_city_name",
  },
  { type: 7, name: "Sourced Price Range", filterKey: "sourced_price_range" },
  {
    type: 8,
    name: "Supplied to ",
    key: "client_type",
    filterKey: "client_type",
  },
  {
    type: 9,
    name: "Seller Ready to Sell",
    key: "ready_to_sell",
    filterKey: "ready_to_sell",
  },
];

export const CAMPAIGN_TYPE = {
  NONE: 0,
  MOTHER: 1,
  SELLER: 2,
};

Object.freeze(CAMPAIGN_TYPE);

export const MARKUP_TYPE = {
  VALUE: 1,
  PERCENTAGE: 2,
};

Object.freeze(MARKUP_TYPE);

let customerList = [
  { id: 1, name: "Select Dayal & Company", cityId: 1 },
  { id: 2, name: "Radhe Krishna Trader", cityId: 2 },
  { id: 3, name: "Shanti Rice Mill", cityId: 2 },
  { id: 4, name: "Ramswami Binshnoi Traders", cityId: 1 },
];

let cityList = {
  "Uttar Pradesh": [
    { id: 1, name: "place1" },
    { id: 2, name: "place2" },
  ],
};

export const getCustomerList = (
  state = "",
  commodity = "",
  variety = "",
  city = "",
  warehouse = "",
  offset = 0,
  limit = 1
) => {
  console.log(
    { state },
    { commodity },
    { variety },
    { city },
    { warehouse },
    { offset },
    { limit }
  );
  if (city) {
    return {
      list: customerList
        .filter((el) => el.cityId == city)
        .slice(offset, offset + limit),
      totalCount: customerList.filter((el) => el.cityId == city).length,
    };
  }
  return {
    list: customerList.slice(offset, offset + limit),
    totalCount: customerList.length,
  };
};
export const getCustomerCitiesFromState = (stateName) => {
  return cityList[stateName] || [];
};

export const READY_TO_SELL = {
  PENDING: 0,
  YES: 1,
  NO: 2,
  EDIT: 3,
};

export const READY_TO_SELL_STATUS_COLOR = {
  0: "#F5A213",
  1: "#00A789",
  2: "#FD531D",
  3: "#00A789",
};

export const SOURCE_COLOR = {
  RETAIL: "blue",
  CORPORATE: "yellow",
  FARMERS: "red",
  FPO: "green",
};
