import { combineReducers } from "redux";
import recent from "./recent.reducer";
import option from "./option.reducer";
import userData from "./userData.reducer";
import translation from "./translate.reducer";
import searchLead from "./search.reducer";
import active from "./Footer";
import auctionData from "./auctionData.reducer";
import GetAllProducts from "./getAllProducts.reducer";
import GetProductDetails from "./getProductDetails.reducer";
import GetSellerProducts from "./getSellerProducts.reducer";
import GetSimilarProducts from "./getSimilarProducts.reducer";
import PostEnquiry from "./postEnquiry.reducer";
import verifyPan from "./verifyPan.reducer";
import marketplaceAuctionData from "./marketplaceAuctionData.reducer";
import GetSellerProfileImages from "./getSellerProfileImages.reducer";
import AddProduct from "./addProduct.reducer";
import UpdateProduct from "./updateProduct.reducer";
import GetCommodityDropdown from "./getCommodityDropdown.reducer";
import GetSellerDetails from "./getSellerDetails.reducer";
import PostSellRequirement from "./postSellRequirement.reducer";
import ContactSeller from "./contactSeller.reducer";
import GetMyRequirements from "./getMyRequirements.reducer";
import MakeRequirementPublic from "./makeRequirement.reducer";
import GetMyRequirementsFilter from "./getMyRequirementsFilter.reducer";
import GetCityByState from "./getCityByState.reducer";
import GetEnamStorage from "./getEnamStorage.reducer";
import blkcnDpReducer from "./blockchain.depositor.reducer";
import priceCaptureState from "./priceCature.reducer";
import { userInfoReducer } from "../_Pages/_webapp/arya2.0/Redux/UserInfo/reducer";
import { aryaShaktiMobileReducer } from "../_Pages/_webapp/arya2.0/Redux/AryaShaktiInfo/reducer";
import { commodityArrayReducer } from "../_Pages/_webapp/arya2.0/Redux/CommodityArray/reducer";
import { userLanguageReducer } from "../_Pages/_webapp/arya2.0/Redux/UserLang/reducer";
import { financeReducer } from "../_Pages/_webapp/arya2.0/Redux/Finance/reducer";
import blockchain from "./blockchain.reducer";
const rootReducer = combineReducers({
  recent,
  option,
  userData,
  translation,
  searchLead,
  active,
  auctionData,
  GetAllProducts,
  GetProductDetails,
  GetSellerProducts,
  GetSimilarProducts,
  PostEnquiry,
  verifyPan,
  marketplaceAuctionData,
  GetSellerProfileImages,
  AddProduct,
  UpdateProduct,
  GetCommodityDropdown,
  GetSellerDetails,
  PostSellRequirement,
  ContactSeller,
  GetMyRequirements,
  MakeRequirementPublic,
  GetMyRequirementsFilter,
  GetCityByState,
  GetEnamStorage,
  blkcnDpReducer,
  priceCaptureState,
  userInfoReducer,
  aryaShaktiMobileReducer,
  userLanguageReducer,
  financeReducer,
  commodityArrayReducer,
  blockchain,
});
export default rootReducer;
