import {
  CLEAR_FINANCE_STATE,
  GET_FINANCE_DATA,
  GET_STATEMENT_DATA,
  GET_TRANSACTION_DATA,
  SET_LOAN_STATEMENT_DATA,
} from "./types";

const initialState = {
  myFinanceData: {},
  statementData: null,
  transactionData: null,
  loanStatementData: [],
  isLoading: false,
};

export const financeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_FINANCE_DATA:
      return { ...state, myFinanceData: payload };
    case GET_STATEMENT_DATA:
      return { ...state, statementData: payload };
    case GET_TRANSACTION_DATA:
      return { ...state, transactionData: payload };
    case SET_LOAN_STATEMENT_DATA:
      return {
        ...state,
        loanStatementData: payload,
      };
    case CLEAR_FINANCE_STATE:
      return { state: {} };

    default:
      return state;
  }
};
