import {
  VERIFY_PAN,
  VERIFY_PAN_FAILED,
  VERIFY_PAN_RESET,
} from "../_actions/actionType";

export const PAN_STATUS = {
  UNKNOWN: "UNKNOWN",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  PENDING: "PENDING",
};

Object.freeze(PAN_STATUS);
const initialState = {
  status: PAN_STATUS.UNKNOWN,
  err: "",
  value: "",
};

const verifyPanReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_PAN:
      return { ...state, status: PAN_STATUS.PENDING, err: "" };
    case VERIFY_PAN_FAILED:
      return { ...state, status: PAN_STATUS.FAILED, err: action.payload };
    case VERIFY_PAN_RESET:
      return initialState;
    default:
      return state;
  }
};

export default verifyPanReducer;
