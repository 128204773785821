import { OPTION_CONSTANT } from '../_constants/Redux/OPTION_CONSTANT'

const INITIAL_STATE = {
    city_id:"",
    state_id:"",
    backupCityArr:""
};

export default function searchLead(state = INITIAL_STATE, action) {
    switch (action.type) {
        case OPTION_CONSTANT.SEARCH_CHECHA:
            return {
                ...state,
                commodity:action.data.commodity,
                stateName:action.data.stateName,
                cityName:action.data.cityName,
                cityArray : action.data.cityArray,
                statePlaceName:action.data?.statePlaceName?action.data.statePlaceName:"",
                nameOfCity:action.data?.nameOfCity?action.data.nameOfCity:"",
                city_id:action.data.cityName?action.data.cityName:state.city_id,
                state_id:action.data.stateName?action.data.stateName:state.state_id,
                backupCityArr : action.data?.cityArray?action.data.cityArray:state.backupCityArr,
            };
        default:
            return state;
    }
}