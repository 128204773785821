import moment from "moment";
import React from "react";
import { isHashCode } from "../../_actions/common";
import { USER_TYPE } from "../../_constants/UserType/USER_TYPE";
export default function isLogin() {
  var result = false;
  if (localStorage.getItem("authToken") !== null) {
    result = true;
  }
  return result;
}

export function setFieldData(options_master, fieldKey, dataObject, result) {
  if (options_master.hasOwnProperty(fieldKey)) {
    options_master[fieldKey].map((master, key1) => {
      // let res1 = dataObject[fieldKey].split(",");
      if (result.includes(master.value)) {
        dataObject[master.label] = 1;
      } else {
        dataObject[master.label] = 0;
      }
    });
  }
  return dataObject;
}

export function isAdmin() {
  var result = false;
  let data = JSON.parse(localStorage.getItem("userDashBoardData"));
  data.member.roles.map((role, index) => {
    if (role.id === 1) {
      result = true;
    }
  });
  return result;
}

export function getCompanyid() {
  var result = "";
  let data = JSON.parse(localStorage.getItem("userDashBoardData"));

  if (data.corporate) {
    result = data.corporate.company_id;
  }
  return result;
}

export function mdyDateFormat(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
}

export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  console.log("Query", query);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  console.log("Query variable %s not found", variable);
}

export function getQueryVariableToken(variable) {
  var query = window.location.search.substring(1);
  console.log("Query", query);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  console.log("Query variable %s not found", variable);
}

export const getQueryParam = (key) => {
  const query = new URLSearchParams(window.location.search);
  return decodeURIComponent(query.get(key)) === "null"
    ? null
    : decodeURIComponent(query.get(key));
};

export function validateBuySell(obj, buysell, isBuySall) {
  let error = {};
  if (buysell.length === 0 && isBuySall) {
    error["buyerSeller"] = "Select Option";
  }
  if (buysell.includes("buyer")) {
    if (obj?.buyerLocation.length === 0) {
      error["buyerLocation"] = "Location is Required";
    }
    if (obj?.buyerCommodity.length > 3) {
      error["buyerCommodity"] = "Max 3 Commodity can be select";
    }
    if (obj?.buyerCommodity.length === 0) {
      error["buyerCommodity"] = "Commodity is Required";
    }
    if (obj?.buyerLocation.length > 3) {
      error["buyerLocation"] = "Max 3 Location can be select";
    }
  }
  if (buysell.includes("seller")) {
    if (obj?.sellerLocation.length === 0) {
      error["sellerLocation"] = "Location is Required";
    }
    if (obj?.sellerCommodity.length > 3) {
      error["sellerCommodity"] = "Max 3 Commodity can be select";
    }
    if (obj?.sellerCommodity.length === 0) {
      error["sellerCommodity"] = "Commodity is Required";
    }
    if (obj?.sellerLocation.length > 3) {
      error["sellerLocation"] = "Max 3 Location can be select";
    }
  }
  return Object.keys(error).length !== 0 ? error : "";
}

export function formatByYYYYMMDDTHHMM(date) {
  if (!moment(date, "YYYY-MM-DD", true).isValid()) return date;
  return moment(date, "YYYY-MM-DD").format("MMM DD, YYYY");
}
export function ObjectInToArray(obj) {
  return Object.keys(obj).map((val) => {
    return obj[val];
  });
}

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isMobileUser = () =>
  window.matchMedia("only screen and (max-width: 1023px)").matches;

export const capitalize = (str, lower = true) => {
  if (str) {
    return (lower ? str.toLowerCase() : str).replace(
      /(?:^|\s|["'([{])+\S/g,
      (match) => match.toUpperCase()
    );
  } else return "";
};

export const debounce = (func, delay) => {
  let debounceTimer;
  return function (...args) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(this, args), delay);
  };
};

export const throttle = (func, delay = 100) => {
  // Previously called time of the function
  let prev = 0;
  return (...args) => {
    let now = new Date().getTime();

    if (now - prev > delay) {
      prev = now;

      return func(...args);
    }
  };
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const errorChecker = (errobj) => {
  let flag = 0;
  Object.keys(errobj).forEach((key) => {
    if (errobj[key]) flag = 1;
  });
  return flag;
};

export const isAnyEmpty = (errobj) => {
  let flag = 0;
  Object.keys(errobj).forEach((key) => {
    if (errobj[key]) flag += 1;
  });
  return flag;
};

export const ShowError = (error, className, style = {}) => {
  if (!error) return null;
  const isMobile = window.matchMedia(
    "only screen and (max-width: 1023px)"
  ).matches;
  return (
    <div
      style={style}
      className={
        (isMobile ? "text-danger " : "fromerror ") +
        (className ? className : "")
      }
    >
      {error}
    </div>
  );
};

export const ClickWithUrl = (url, blank) => {
  const a = document.createElement("a");
  a.href = url;
  if (blank) {
    a.target = "_blank";
  }
  a.click();
};

export async function getUserBlockChainType(TYPE, isKyc) {
  let userRoles = JSON.parse(localStorage.getItem("userDashBoardData"))?.member
    ?.roles;

  var isTrue = false;
  if (localStorage.getItem("authToken") !== null && isKyc) {
    if (
      userRoles.find((obj) => +obj.id === parseInt(TYPE)) &&
      TYPE === USER_TYPE.BANKER
    ) {
      isTrue = true;
    }
    let checkHash = await isHashCode();
    if (TYPE !== USER_TYPE.BANKER && checkHash) {
      isTrue = true;
    }
  }
  return isTrue;
}

export function isBankerCheck() {
  let userRoles = JSON.parse(localStorage.getItem("userDashBoardData"))?.member
    ?.roles;
  let isKyc = localStorage.getItem("isKyc");
  var isTrue = false;

  if (isKyc == 1 && userRoles.find((obj) => +obj.id === USER_TYPE.BANKER)) {
    isTrue = true;
  }

  return isTrue;
}

export function isDepositorCheck() {
  let userRoles = JSON.parse(localStorage.getItem("userDashBoardData"))?.member
    ?.roles;
  let isKyc = localStorage.getItem("isKyc");
  var isTrue = false;

  if (isKyc == 1 && !userRoles.find((obj) => +obj.id === USER_TYPE.BANKER)) {
    isTrue = true;
  }

  return isTrue;
}
