import toastr from "toastr";
import tcs from "./customToastrStyles.module.css";
export const customToastr = ({
  text,
  type = "success",
  positionClass = tcs["toast-top-center1"],
  toastClass = tcs["custom-toastr"],
  timeOut = 1,
}) => {
  toastr.options = {
    timeOut: timeOut,
    positionClass,
    toastClass,
    preventDuplicates: true,
    // showDuration: 30,
    // hideDuration: 600000,
  };
  toastr[type](text);
  //   toastr.success(text);
};
