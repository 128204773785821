import {
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_DETAILS_FAILURE,
  GET_PRODUCT_DETAILS_RESET,
  GET_PRODUCT_DETAILS_SUCCESS,
} from "../_actions/actionType";

const initialState = {
  data: {},
  isFetching: false,
  error: [],
};

export default function GetProductDetails(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_DETAILS:
      return {
        ...state,
        data: {},
        isFetching: true,
        error: [],
      };
    case GET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        error: [],
      };
    case GET_PRODUCT_DETAILS_FAILURE:
      return {
        ...state,
        isFetching: false,
        data: {},
        error: action.err,
      };
    case GET_PRODUCT_DETAILS_RESET:
      return {
        ...state,
        isFetching: false,
        data: {},
        error: [],
      };
    default:
      return state;
  }
}
