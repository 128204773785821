import { SET_USER_LANGUAGE, UPDATE_USER_LANGUAGE } from "./types";
const initialLangState = { text: "English", id: 1, lang_id: "en" };
export const userLanguageReducer = (
  state = initialLangState,
  { type, payload }
) => {
  switch (type) {
    case SET_USER_LANGUAGE:
      return { ...payload };
    case UPDATE_USER_LANGUAGE:
      return { ...state, ...payload };

    default:
      return state;
  }
};
