import React, { useContext, useState } from 'react'

const HeaderContext = React.createContext("")

const HeaderProducer = (props) => {
    const [app, setApp] = useState("")
    const setValue = (val) => setApp(val)

    return <HeaderContext.Provider value={{ app, setValue }}>
        {props.children}
    </HeaderContext.Provider>
}

export default HeaderProducer;

export const useAppContext=()=>{
    return useContext(HeaderContext)
}