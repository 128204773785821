import {
  POST_SELL_REQUIREMENT,
  POST_SELL_REQUIREMENT_FAILURE,
  POST_SELL_REQUIREMENT_RESET,
  POST_SELL_REQUIREMENT_SUCCESS,
} from "../_actions/actionType";

const initialState = {
  data: {},
  isFetching: false,
  error: [],
};

export default function PostSellRequirement(state = initialState, action) {
  switch (action.type) {
    case POST_SELL_REQUIREMENT:
      return {
        ...state,
        data: {},
        isFetching: true,
        error: [],
      };
    case POST_SELL_REQUIREMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        error: [],
      };
    case POST_SELL_REQUIREMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        data: {},
        error: action.err,
      };
    case POST_SELL_REQUIREMENT_RESET:
      return {
        ...state,
        isFetching: false,
        data: {},
        error: [],
      };
    default:
      return state;
  }
}
