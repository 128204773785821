 export const URL_CONFIG = {
    API_URL: 'https://api.arya.ag/api/',
    PROD_BASE_PATH: 'https://api.arya.ag/image/',
    PROD_DOMAIN: 'https://www.arya.ag/',
    API_bd_URL: 'https://api.arya.ag/bd/',
    WH_RAZORPAY_KEY: 'rzp_live_GH3GlEfhioFEJi',
    WH_RAZORPAY_SECRET_KEY: 'Sym0vPmYIQzcLs4KbPxUKEPe',
    PLAN_RAZORPAY_KEY: 'rzp_live_GH3GlEfhioFEJi',
    PLAN_RAZORPAY_SECRET_KEY: 'Sym0vPmYIQzcLs4KbPxUKEPe',
    PAY_LOAN_RAZORPAY_KEY: 'rzp_live_rgNb4Lazxao3P2',
    PAY_LOAN_RAZORPAY_SECRET_KEY: 'zo0sYLsZ0NdnQ8o8rNm6b9h2',
    STOCK_RAZORPAY_KEY: 'rzp_live_rgNb4Lazxao3P2',
    STOCK_RAZORPAY_SECRET_KEY: '',
    RELEASE_STOCK: 'rzp_live_rgNb4Lazxao3P2',
    RELEASE_SECRET: '8UjtdpiH3mn7Ui744zeg6wOG',
    PROD_DOMAIN_IMAGES: window.location.origin + '/assets/Images/',
    MARKETPLACE_URL:'https://market.arya.ag/en',
    MARKET_LOGIN_URL:'https://market.arya.ag/en/user/login',
    SINGLE_SIGNIN:true,
    SELL_COMMODITY:"https://market.arya.ag/en/browse/buy-commodity",
    RMS_URL: 'http://rms.arya.ag?',
    ARYA_LOGOUT:'https://market.arya.ag?actionLogout=1',
    ENVIROEMENT:'PRODUTION',
    WHATS_APP: '919289312348',
    IMG_URL: window.location.origin + '/assets/Images/',
    SKIPPABLE_BANK_ACCOUNT:'135791197531',
    ORIGIN_URL:'https://www.arya.ag',
    GOOGLE_API_KEY : "AIzaSyAGONI6_PRCWSKosjo_q1q4VET-Cqy52f8",
    FIREBASE_API_KEY : "AIzaSyBcWlYe02ix7u-F9zTXTl193ABwGtxROiE",
    GEOCODE_API_KEY : "AIzaSyBLTWd9NlDnXLkyEhGZpTrGp7w9yxISuAw"
 }
