import { TRANSLATION_CONSTANT } from '../_constants/Redux/index'
const INITIAL_STATE = {
    translation: {},
    searchedMemory: "",
    wh_Arr:[]
};

export default function translation(state = INITIAL_STATE, action) {
    switch (action.type) {
        case TRANSLATION_CONSTANT.TRANSLATION_SAVE:
            return {
                ...state,
                translation: action.data
            };
        case TRANSLATION_CONSTANT.SEARCHED_MEMORY:
            return {
                ...state,
                searchedMemory: action.searchdata
            };
        case TRANSLATION_CONSTANT.SEARCHED_WAREHOUSE:
            return {
                ...state,
                wh_Arr: Array.isArray(action.data)?action.data:[]
            };
        default:
            return state;
    }
}