import {
  CONTACT_SELLER,
  CONTACT_SELLER_FAILURE,
  CONTACT_SELLER_RESET,
  CONTACT_SELLER_SUCCESS,
} from "../_actions/actionType";

const initialState = {
  data: {},
  isFetching: false,
  error: [],
};

export default function ContactSeller(state = initialState, action) {
  switch (action.type) {
    case CONTACT_SELLER:
      return {
        ...state,
        data: {},
        isFetching: true,
        error: [],
      };
    case CONTACT_SELLER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        error: [],
      };
    case CONTACT_SELLER_FAILURE:
      return {
        ...state,
        isFetching: false,
        data: {},
        error: action.err,
      };
    case CONTACT_SELLER_RESET:
      return {
        ...state,
        isFetching: false,
        data: {},
        error: [],
      };
    default:
      return state;
  }
}
